<template>
  <div class="hello">
    <div class="container" >
      <div class="content">
        <div class="title">{{titleText}}</div>
        <br>
        <div class="sectext" v-text="element_title[0]"></div>
        <br><br><br><br><br>
        <button class="btn btn-blue" v-text="element_title[1]" @click="LoginPage"></button>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <button class="btn btn-green" v-text="element_title[2]" @click="RegPage"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    titleText : String
  },
  data:function () {
    return {
      myCode:'',
      location: 'GB',
      element_title:['高速商用ネットワークサービ11ス','> ログイン','> サインアップ'],
    };
  },
  methods:{
    LoginPage:function () {
      window.location.href = "/auth/login";
    },
    RegPage:function () {
      window.location.href = "/auth/register";
    },
    parsedir:function(strs){
      return decodeURIComponent(escape(window.atob(strs)));
    },
    isAffWebsite:function(){
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] == "code"){
          if(pair[1].length > 4){
            pair[1] = pair[1].substring(0,4);
            console.log(pair[1]);
          }
          this.reg = this.reg + "?code=" + pair[1];
          this.myCode = pair[1];
        }else{
          this.myCode = '';
        }
        if(pair[0] == "lang"){
          if(pair[1]=="cn"){
            this.lang = 'cn';
            console.log('omg');
          }
        }

      }

    },

  },
  mounted() {
    this.isAffWebsite()
    if(this.lang == 'cn' || this.myCode != '') {
      this.lang = 'cn';
      console.log('omgs');
      let translate_lan = [
        "5Yqg6YCf5oKo55qE572R57uc",
        'PiDnmbvlvZU=',
        'PiDms6jlhow='
      ];
      for (let a = 0; a < translate_lan.length; a++) {
        this.element_title[a] = this.parsedir(translate_lan[a]);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
  .hello{
    margin-top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
    vertical-align: auto;
    horiz-align: center;
  }
.container {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.btn {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
  .btn-green{
    background-color: forestgreen;
  }
  .btn-blue{
    background-color: dodgerblue;
  }
  .title{
    font-size: 42px;
    color: white;
  }
  .sectext{
    font-size: 26px;
    color: lightblue;
  }
</style>
