<template>
  <div id="app">
    <div class="bgimgs">
      <HelloWorld titleText="サーモン ネットワーク"/>
    </div>

  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bgimgs {
  height: 100%;
  width: 100%;
  z-index: -1;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image:url('~@/assets/bg.webp');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
}
</style>
